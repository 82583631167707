var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('base-text-field',{staticClass:"field-search",attrs:{"placeholder":"Быстрый поиск","prepend-inner-icon":"$iconify_ion-search-outline","clear-icon":"$iconify_ion-close-circle-outline","prepend-inner-icon-color":this.$vuetify.theme.themes.light['neutral-500'],"clearable":"","hide-details":""},on:{"input":_vm.delayedFetchData},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetchData}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $iconify_feather-refresh-ccw ")]),_c('span',[_vm._v("Обновить")])],1)],1),(
          _vm.userPermissionsSet.has('program-sales-export') ||
          _vm.authUser.is_super_admin)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"crm__header_new-btn",staticStyle:{"margin-right":"25px"},attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_c('iconify-icon',{staticStyle:{"margin-right":"5px"},attrs:{"icon":"clarity-export-line","width":"21"}}),_vm._v(" Экспорт ")],1)]}}],null,false,1097860669)},[_c('v-list',_vm._l(([
              { text: 'xlsx', value: 'xlsx' },
              { text: 'csv', value: 'csv' } ]),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.exportSales(item.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","to":{name:'SaleForm'}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $iconify_plus-circle ")]),_c('span',[_vm._v("Создать")])],1)],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"width":"1024px"},attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"plus-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"options":_vm.tableOptions,"single-expand":true,"expanded":_vm.expanded,"item-key":"id","show-expand":"","hide-default-footer":"","multi-sort":"","server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.tableOptions=$event},"update:expanded":function($event){_vm.expanded=$event},"update:sort-by":function($event){return _vm.fetchData()},"update:sort-desc":function($event){return _vm.fetchData()},"click:row":_vm.changeExpand},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"body-s-semibold",attrs:{"cols":"auto"}},[_vm._v(" Комментарий: ")]),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(item.comment || '-')+" ")])],1),(item.tran_group)?_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"body-s-semibold",attrs:{"cols":"auto"}},[_vm._v(" Комментарий к бон. операции: ")]),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(item.tran_group.comment || '-')+" ")])],1):_vm._e(),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.salePositionsHeaders,"items":item.positions,"hide-default-footer":"","dense":""}},[(item.positions.length > 0)?_c('template',{slot:"body.append"},[_c('tr',{staticClass:"text-end"},[_c('td',{attrs:{"colspan":"5"}},[_c('span',{staticClass:"body-s-semibold"},[_vm._v("Итого:")])]),_c('td',[_vm._v(" "+_vm._s(item.positions.reduce(function (sum, position){ return sum + position.sum; }, 0).toLocaleString(undefined, {style: _vm.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: _vm.currency.alpha3}))+" ")])])]):_vm._e()],2)],1)],1)],1)]}},{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"body-s-medium"},[_vm._v(" "+_vm._s(_vm.getDate(item.created_at))+" ")]),_c('div',{staticClass:"cell-hint"},[_vm._v(" "+_vm._s(_vm.getTime(item.created_at))+" ")])]}},{key:"item.discount",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.total_discount_format))])]}},{key:"item.client",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[(item.account && item.account.user)?_c('img',{staticClass:"cell-avatar",staticStyle:{"position":"relative","top":"-5px","cursor":"pointer"},attrs:{"src":item.account.user.avatar},on:{"click":function($event){$event.stopPropagation();return _vm.userSidePanel(item.account)}}}):_vm._e(),_c('div',[(item && item.account && item.account.user)?_c('div',{staticClass:"body-s-medium",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.userSidePanel(item)}}},[_vm._v(" "+_vm._s(item.account.client_name || _vm.$t('no_name'))+" ")]):_c('div',{staticClass:"body-s-medium"},[_vm._v(" Анонимная продажа ")]),(item && item.account && item.account.user)?_c('div',{staticClass:"cell-hint"},[_vm._v(" "+_vm._s(_vm.getLastActivity(item.account.user.last_activity))+" ")]):_c('div',{staticClass:"cell-hint"})])])]}},{key:"item.contacts",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"body-s-medium"},[(item.account && item.account.user.phone)?_c('a',{attrs:{"href":'tel:+' + item.account.user.phone},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("+"+_vm._s(item.account.user.phone))]):_c('div',[_vm._v("-")])]),_c('div',{staticClass:"cell-hint"},[_vm._v(" "+_vm._s(item.account && item.account.user && item.account.user.email ? item.account.user.email : '')+" ")])]}},{key:"item.operator",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticClass:"cell-avatar",style:(("background-color: " + (_vm.program.bgcolor1) + ";")),attrs:{"src":item.user
                ? item.user.avatar
                : _vm.program.logo_short}}),_c('div',{staticClass:"body-s-medium"},[_vm._v(" "+_vm._s(item && item.user ? (item.user.name ? item.user.name : '') + ' ' + (item.user.lastname ? item.user.lastname : '' ) : _vm.program.name)+" ")])])]}},{key:"item.program_shop",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.program_shop? item.program_shop.name : '-'))])]}},{key:"item.value",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"body-s-medium"},[_vm._v(" "+_vm._s((item.value / 100).toLocaleString(undefined, {style: _vm.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: _vm.currency.alpha3}))+" ")])])]}},{key:"item.cash_value",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"body-s-medium"},[_vm._v(" "+_vm._s(item.cash_value != null ? (item.cash_value / 100).toLocaleString(undefined, {style: _vm.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: _vm.currency.alpha3}): '-')+" ")])])]}},{key:"item.bonuses",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(item.tran_group && item.tran_group.abst_view && item.tran_group.abst_view.length >0 && item.tran_group.abst_view[0].value > 0)?_c('div',{staticClass:"body-s-semibold cell-text-success"},[_vm._v(" "+_vm._s('+'+_vm.bonusValueStr(item.tran_group.abst_view))+" ")]):(item.tran_group && item.tran_group.abst_view && item.tran_group.abst_view.length >0 && item.tran_group.abst_view[0].value < 0)?_c('div',{staticClass:"body-s-semibold cell-text-error"},[_vm._v(" "+_vm._s(_vm.bonusValueStr(item.tran_group.abst_view))+" ")]):_c('div',{staticClass:"body-s-semibold"},[_vm._v(" - ")])])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [(_vm.userPermissionsSet.has('program-sales-delete') || _vm.authUser.is_super_admin)?_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"item.deleted_at",fn:function(ref){
                var item = ref.item;
return [(item.deleted_at)?_c('base-tooltip',{attrs:{"bottom":"","text":("Удалена " + (item.deleted_at_display)),"color":"error"}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" $iconify_feather-trash ")])],1):_vm._e()]}}],null,true)})],1)],1),_c('v-row',{staticClass:"pagination",attrs:{"align":"center"}},[_c('v-col',[_c('div',{staticClass:"table-pagination-block"},[_c('div',{staticStyle:{"margin-right":"20px"}},[_vm._v(" Всего "+_vm._s(_vm.totalCount)+" "+_vm._s(_vm.getWord(_vm.totalCount, _vm.wordOperations))+" на "+_vm._s(_vm.pagesCount)+" "+_vm._s(_vm.getWord(_vm.pagesCount, _vm.wordPages))+" ")]),_c('select-page-limit',{attrs:{"min-width":"200px","items":_vm.paginationOptions,"model":_vm.tableOptions.itemsPerPage,"item-value":"value","item-label":"text"},on:{"update:model":function($event){return _vm.$set(_vm.tableOptions, "itemsPerPage", $event)}}}),_c('div',{staticClass:"app__spacer"}),_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"next-icon":"fas fa-chevron-right","prev-icon":"fas fa-chevron-left","length":_vm.pagesCount,"total-visible":7,"circle":""},model:{value:(_vm.tableOptions.page),callback:function ($$v) {_vm.$set(_vm.tableOptions, "page", $$v)},expression:"tableOptions.page"}})],1)],1)])],1),_c('side-panel-edit-client',{attrs:{"mode":_vm.sidePanelStatus.mode,"data":_vm.sidePanelStatus.data},on:{"update:mode":function($event){return _vm.$set(_vm.sidePanelStatus, "mode", $event)}},model:{value:(_vm.sidePanelStatus.active),callback:function ($$v) {_vm.$set(_vm.sidePanelStatus, "active", $$v)},expression:"sidePanelStatus.active"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }