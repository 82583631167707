<template>
  <div>
    <v-row justify="end">
      <v-col cols="4">
        <base-text-field
          v-model.trim="search"
          class="field-search"
          placeholder="Быстрый поиск"
          prepend-inner-icon="$iconify_ion-search-outline"
          clear-icon="$iconify_ion-close-circle-outline"
          :prepend-inner-icon-color="this.$vuetify.theme.themes.light['neutral-500']"
          clearable
          hide-details
          @input="delayedFetchData"
        />
      </v-col>
      <v-col cols="auto">
        <v-btn
          color="primary"
          @click="fetchData"
        >
          <v-icon left>
            $iconify_feather-refresh-ccw
          </v-icon>
          <span>Обновить</span>
        </v-btn>
      </v-col>
      <v-col
          v-if="
            userPermissionsSet.has('program-sales-export') ||
            authUser.is_super_admin"
          cols="auto">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="crm__header_new-btn"
                style="margin-right: 25px"
                color="secondary"
                v-bind="attrs"
                v-on="on"
            >
              <iconify-icon
                  icon="clarity-export-line"
                  width="21"
                  style="margin-right: 5px"
              />
              Экспорт
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                v-for="(item, index) in [
                { text: 'xlsx', value: 'xlsx' },
                { text: 'csv', value: 'csv' },
              ]"
                :key="index"
                @click="exportSales(item.value)"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="auto">
        <v-btn
          color="primary"
          :to="{name:'SaleForm'}"
        >
          <v-icon left>
            $iconify_plus-circle
          </v-icon>
          <span>Создать</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        style="width: 1024px"
      >
        <v-data-table
          :headers="tableHeaders"
          :items="tableData"
          :options.sync="tableOptions"
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="id"
          show-expand
          class="plus-table"
          hide-default-footer
          multi-sort
          :server-items-length="totalCount"
          @update:sort-by="fetchData()"
          @update:sort-desc="fetchData()"
          @click:row="changeExpand"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row justify="start">
                <v-col
                  cols="auto"
                  class="body-s-semibold"
                >
                  Комментарий:
                </v-col> <v-col cols="auto">
                  {{ item.comment || '-' }}
                </v-col>
              </v-row>
              <v-row
                v-if="item.tran_group"
                justify="start"
              >
                <v-col
                  cols="auto"
                  class="body-s-semibold"
                >
                  Комментарий к бон. операции:
                </v-col><v-col cols="auto">
                  {{ item.tran_group.comment || '-' }}
                </v-col>
              </v-row>
              <v-row justify="start">
                <v-col>
                  <v-data-table
                    :headers="salePositionsHeaders"
                    :items="item.positions"
                    hide-default-footer
                    dense
                  >
                    <template
                      v-if="item.positions.length > 0"
                      slot="body.append"
                    >
                      <tr class="text-end">
                        <td
                          colspan="5"
                        >
                          <span class="body-s-semibold">Итого:</span>
                        </td>
                        <td>
                          {{ item.positions.reduce((sum, position)=> sum + position.sum, 0).toLocaleString(undefined, {style: currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: currency.alpha3}) }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </td>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <div class="body-s-medium">
              {{ getDate(item.created_at) }}
            </div>
            <div
              class="cell-hint"
            >
              {{ getTime(item.created_at) }}
            </div>
          </template>

          <template v-slot:[`item.discount`]="{ item }">
            <span>{{ item.total_discount_format }}</span>
          </template>

          <template v-slot:[`item.client`]="{ item }">
            <div style="display: flex;">
              <img
                v-if="item.account && item.account.user"
                class="cell-avatar"
                style="position: relative; top: -5px; cursor: pointer;"
                :src="item.account.user.avatar"
                @click.stop="userSidePanel(item.account)"
              >
              <div>
                <div
                  v-if="item && item.account && item.account.user"
                  class="body-s-medium"
                  style="cursor: pointer;"
                  @click.stop="userSidePanel(item)"
                >
                  {{ item.account.client_name || $t('no_name') }}
                  <!-- {{ getFIO(item.account.user) }} -->
                </div>
                <div
                  v-else
                  class="body-s-medium"
                >
                  Анонимная продажа
                </div>
                <div
                  v-if="item && item.account && item.account.user"
                  class="cell-hint"
                >
                  {{ getLastActivity(item.account.user.last_activity) }}
                </div>
                <div
                  v-else
                  class="cell-hint"
                />
              </div>
            </div>
          </template>

          <template v-slot:[`item.contacts`]="{ item }">
            <div class="body-s-medium">
              <a @click.stop="" v-if="item.account && item.account.user.phone" :href="'tel:+' + item.account.user.phone ">+{{ item.account.user.phone }}</a>
              <div v-else>-</div>
            </div>
            <div
              class="cell-hint"
            >
              {{ item.account && item.account.user && item.account.user.email ? item.account.user.email : '' }}
            </div>
          </template>

          <template v-slot:[`item.operator`]="{ item }">
            <div style="display: flex; align-items: center">
              <img
                class="cell-avatar"
                :style="`background-color: ${program.bgcolor1};`"
                :src="item.user
                  ? item.user.avatar
                  : program.logo_short"
              >
              <div class="body-s-medium">
                {{ item && item.user ? (item.user.name ? item.user.name : '') + ' ' + (item.user.lastname ? item.user.lastname : '' ) : program.name }}
              </div>
            </div>
          </template>

          <template v-slot:[`item.program_shop`]="{ item }">
            <span>{{ item.program_shop? item.program_shop.name : '-' }}</span>
          </template>
          <template v-slot:[`item.value`]="{ item }">
            <div style="display: flex; align-items: center">
              <div class="body-s-medium">
                {{ (item.value / 100).toLocaleString(undefined, {style: currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: currency.alpha3}) }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.cash_value`]="{ item }">
            <div style="display: flex; align-items: center">
              <div class="body-s-medium">
                {{ item.cash_value != null ? (item.cash_value / 100).toLocaleString(undefined, {style: currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: currency.alpha3}): '-' }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.bonuses`]="{ item }">
            <div style="display: flex; align-items: center">
              <div
                v-if="item.tran_group && item.tran_group.abst_view && item.tran_group.abst_view.length >0 && item.tran_group.abst_view[0].value > 0"
                class="body-s-semibold cell-text-success"
              >
                {{ '+'+bonusValueStr(item.tran_group.abst_view) }}
              </div>
              <div
                v-else-if="item.tran_group && item.tran_group.abst_view && item.tran_group.abst_view.length >0 && item.tran_group.abst_view[0].value < 0"
                class="body-s-semibold cell-text-error"
              >
                {{ bonusValueStr(item.tran_group.abst_view) }}
              </div>
              <div
                v-else
                class="body-s-semibold"
              >
                -
              </div>
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              v-if="userPermissionsSet.has('program-sales-delete') || authUser.is_super_admin"
              icon
              @click="deleteItem(item)"
              color="error"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.deleted_at`]="{ item }">
            <base-tooltip
              v-if="item.deleted_at"
              bottom
              :text="`Удалена ${item.deleted_at_display}`"
              color="error"
            >
              <v-icon
                color="error"
              >
                $iconify_feather-trash
              </v-icon>
            </base-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row
      align="center"
      class="pagination"
    >
      <v-col>
        <div class="table-pagination-block">
          <div
            style="margin-right: 20px;"
          >
            Всего {{ totalCount }} {{ getWord(totalCount, wordOperations) }} на {{ pagesCount }} {{ getWord(pagesCount, wordPages) }}
          </div>

          <select-page-limit
            min-width="200px"
            :items="paginationOptions"
            :model.sync="tableOptions.itemsPerPage"
            item-value="value"
            item-label="text"
          />

          <div class="app__spacer" />

          <div class="text-center">
            <v-pagination
              v-model="tableOptions.page"
              next-icon="fas fa-chevron-right"
              prev-icon="fas fa-chevron-left"
              :length="pagesCount"
              :total-visible="7"
              circle
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <side-panel-edit-client
      v-model="sidePanelStatus.active"
      :mode.sync="sidePanelStatus.mode"
      :data="sidePanelStatus.data"
    />
  </div>
</template>

<script>
  import DataTable from '@/mixins/dataTable'
  import User from '@/mixins/user.js'
  import SelectPageLimit from '@/components/dialogs/SelectPageLimit'
  import FormatNumber from '@/mixins/formatNumber'
  import FormatNumberFloat from '@/mixins/formatNumberFloat'
  import Routing from '@/mixins/routing'
  import Permission from '@/mixins/permission'

  export default {
    components: {
      SelectPageLimit,
    },
    props: {
      currency: {
        type: Object,
        default: () => {
          return {
            alpha3: 'RUB'
          }
        }
      }
    },
    mixins: [DataTable, FormatNumber, Routing, User, FormatNumberFloat, Permission],
    data () {
      return {
        loadingList: false,
        timerId: null,
        sidePanelStatus: {
          active: false,
          mode: 'edit',
          data: null,
        },
        tableOptions: {
          page: 1,
          itemsPerPage: 10,
        },
        search: null,
        paginationOptions: [
          { text: '10 на странице', value: 10 },
          { text: '25 на странице', value: 25 },
          { text: '50 на странице', value: 50 },
          { text: '100 на странице', value: 100 },
          { text: '150 на странице', value: 150 },
          { text: '250 на странице', value: 250 },
          { text: '500 на странице', value: 500 },
        ],
        salePositionsHeaders: [
          { text: 'Наименование', align: 'start', value: 'name' },
          { text: 'Кол-во', align: 'end', value: 'quantity_format' },
          { text: 'Цена', align: 'end', value: 'price_rub_format' },
          { text: 'Скидка', align: 'end', value: 'discount_percent_format' },
          { text: 'Скидка', align: 'end', value: 'discount_rub_format' },
          { text: 'Сумма', align: 'end', value: 'sum_format' },
        ],
        expanded: [],
        
        wordPages: ['странице', 'страницах', 'страницах'],
        wordOperations: ['продажа', 'продажи', 'продаж'],
      }
    },
    computed: {
      tableHeaders() {
        return [
          {
            text: 'ID',
            align: 'start',
            value: 'id',
          },
          {
            text: 'Дата',
            align: 'start',
            value: 'created_at',
          },
          {
            text: 'Клиент',
            value: 'client',
            sortable: false,
          },
          {
            text: 'Контакты',
            value: 'contacts',
            sortable: false,
          },
          {
            text: 'Торг. точка',
            value: 'program_shop',
            sortable: false,
          },
          {
            text: 'Оператор',
            value: 'operator',
            sortable: false,
          },
          {
            text: 'Чек',
            value: 'value',
          },
          {
            text: 'Скидка',
            value: 'discount',
          },
          {
            text: 'Оплата',
            value: 'cash_value',
          },
          {
            text: 'Бонусы',
            value: 'bonuses',
            sortable: false,
          },
          {
            text: '',
            value: 'deleted_at',
            sortable: false,
            width: '1em',            
          },
          {
            text: 'Действие',
            sortable: false,
            value: 'action',
            hidden: !(this.userPermissionsSet.has('program-sales-delete') || this.authUser.is_super_admin),
          },
          {
            text: '',
            value: 'data-table-expand',
          },
        ].filter(item => !item.hidden)
      },
      tableData () {
        return this.$store.getters['selling/table/tableData'].map(item => {
          item.deleted_at_display = item.deleted_at ? this.$moment.utc(item.deleted_at).local().format(this.$config.date.DATETIME_FORMAT) : null
          item.positions = (item.positions || []).map(position => {
            position = Object.assign(position, position.pivot)
            position.quantity_format = position.quantity.toLocaleString()
            position.price_rub = position.price != null ? position.price / 100 : null
            position.price_rub_format = position.price != null ? position.price_rub.toLocaleString(undefined, {style: this.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.currency.alpha3}) : '-'
            position.discount_percent = 0
            position.discount_percent_format = '-'
            position.discount_rub_format = '-'
            if (position.price != null && position.discounted_price != null) {
              if (position.price > 0) {
                position.discount_percent = (position.price - position.discounted_price) * 100 / position.price
              } else {
                position.discount_percent = 0
              }
              position.discount_rub = (position.price - position.discounted_price) * position.quantity / 100
              position.discount_rub_format = position.discount_rub.toLocaleString(undefined, {style: this.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.currency.alpha3})
              position.discount_percent_format = Number(position.discount_percent.toFixed(2)).toLocaleString() + " %"
            }

            position.sum_format = '-'
            position.sum = 0
            if (position.discounted_price != null) {
              position.sum = position.discounted_price * position.quantity / 100
              position.sum_format = (position.discounted_price * position.quantity / 100).toLocaleString(undefined, {style: this.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.currency.alpha3})
            } else if (position.price != null) {
              position.sum = position.price * position.quantity / 100
              position.sum_format = (position.price * position.quantity / 100).toLocaleString(undefined, {style: this.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.currency.alpha3})
            }

            return position
          })
          item.value_format = (item.value / 100)
          item.total_discount_format = item.cash_value ? ((item.value - item.cash_value) / 100).toLocaleString(undefined, {style: this.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.currency.alpha3}) : '-'
          item.cash_value_format = item.cash_value ? (item.cash_value / 100).toLocaleString(undefined, {style: this.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.currency.alpha3}) : '-'

          return item
        })
      },
      totalCount () {
        return this.$store.getters['selling/table/totalCount']
      },
      pagesCount () {
        const count = Math.ceil(this.totalCount / this.tableOptions.itemsPerPage)
        console.log('COUNT')
        console.log(count)
        console.log('COUNT')
        if (count) {
          if (this.tableOptions.page > count) {
            this.setTableOptionPage(count)
          }
          return count
        }
        this.setTableOptionPage(1)
        return 1
      },
      program () {
        return this.$store.getters['company/program/program']
      },
      period () {
        return this.$store.getters['reference/date_selection/period']
      },
      filter () {
        return this.$store.getters['selling/filter/filter']
      },
    },
    watch: {
      program (v) {
        if (v) this.fetchData()
      },
      filter (v) {
        if (v) this.fetchData()
      },
      period (v) {
        if (v) this.fetchData()
      },
      'tableOptions.page' (v) {
        console.log(this.tableOptions)
        if (v) this.fetchData()
      },
      'tableOptions.itemsPerPage' (v) {
        console.log(this.tableOptions)
        if (v) this.fetchData()
      },
    },
    created () {
      this.fetchData()
    },
    methods: {
      async exportSales(type) {
        await this.$store.dispatch("selling/table/exportSales", {
          programId: this.program.id,
          type,
          email: this.authUser.email,
          start_period: this.period.start,
          end_period: this.period.end,
        });
      },
      changeExpand (item) {
        const index = this.expanded.findIndex(x => x.id === item.id)
        if (index >= 0) {
          this.expanded.splice(index, 1)
        } else {
          this.expanded.push(item)
        }
      },
      delayedFetchData(){
        if (this.timerId)
        {
          clearTimeout(this.timerId)
          console.log("ОЧИСТКА ИНТЕРВАЛА", this.timerId)
        }
        this.timerId = setTimeout(this.fetchData, 800)
        console.log("НОВЫЙ ИНТЕРВАЛ", this.timerId)
      },
      setTableOptionPage (val) {
        this.tableOptions.page = val
      },
      bonusValueStr (data) {
        let sum = 0
        data.forEach(item => {
          sum += item.value
        })
        //console.log('bonusValue')
        //console.log(sum)
        //console.log('bonusValue')
        return sum.toLocaleString()
      },
      userSidePanel (item) {
        // const user = item.user
        // user.id = item.id
        this.sidePanelStatus.mode = 'edit'
        this.sidePanelStatus.data = Object.assign({
          program_id: item.program_id,
        }, item.account.user, item.account)
        this.sidePanelStatus.active = true
      },
      getDate (date) {
        if (date) return this.$moment.utc(date).local().format(this.$config.date.DATE_FORMAT)
        return '-'
      },
      getTime (date) {
        if (date) {
          const d = this.$moment.utc(date).local().format('HH:mm')
          return 'в ' + d
        }
        return '-'
      },
      getLastActivity (date) {
        if (date) return 'Был(а) в сети ' + this.$moment.utc(date).local().format(this.$config.date.DATETIME_FORMAT_MIN2)
        return 'Был(а) в сети - '
      },
      getValue (value) {
        value = Number(value)
        if (value >= 0) return `<span style="color: #00D15D;">+${this.formatNumberString(value)}</span>`
        return `<span style="color: #EA4C2A;">${this.formatNumberString(value)}</span>`
      },
      getWord (number, words) {
        const cases = [2, 0, 1, 1, 1, 2]
        return words[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
      },
      async fetchData () {
        this.loadingList = true
        const list = {
          program_id: this.program.id,
          start_period: this.period.start,
          end_period: this.period.end,
          // filter: this.filter,
          offset: this.getOffset(this.tableOptions.page, this.tableOptions.itemsPerPage),
          limit: this.tableOptions.itemsPerPage,
          sortable: this.getSortable(this.tableOptions.sortBy, this.tableOptions.sortDesc),
          search: this.search,
        }
         console.log('table/list')
         console.log(list)
        try {
          await this.$store.dispatch('selling/table/table', list)
        } finally {
          this.loadingList = false
        }
      },
      async deleteItem(item) {
        
        try {
          await this.$confirm(
            `Удалить продажу с возвратом бонусной операции?`,
            'Удаление продажи',
            {
              confirmButtonText: 'Удалить',
              cancelButtonText: 'Отмена',
              type: 'warning',
            },
          )
          await this.$store.dispatch('selling/table/delete', {programId: this.program.id, id: item.id })
        } catch (error) {
          console.log(error)
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

.cell-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #2A2A34;
}

.cell-text-bold {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #2A2A34;
}

.cell-hint {
  margin-top: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: #9191A1;
}

.cell-avatar {
  margin-right: 8px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
}

.table-pagination-block {
  display: flex;
  align-items: center;

  .table-pagination-block-select {
    position: relative;
    top: 6px;
    left: 20px;
    width: 250px;
  }
}
</style>
